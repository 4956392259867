export const limitTutorialSteps = [
    {
        title: ' Welcome',
        intro: 'Welcome to the tutorial mode for submitting a limit order on Galaxgate.',
    },
    {
        element: '#share_button',
        title: 'Share button',
        intro: 'This is the share button ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#settings_button',
        title: 'Limit Settings',
        intro: 'Use this menu to adjust your slippage tolerance, as well as control your confirmation methods. ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#limit_sell_qty',
        title: 'Sell Quantity',
        intro: 'Enter your sell amount here ',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#limit_sell_token_selector',
        title: 'Sell Token',
        intro: 'Select your sell token',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#limit_buy',
        title: 'Buy',
        intro: 'Repeat the same step for your buy token and quantity',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '#limit_rate',
        title: 'Limit Rate',
        intro: 'Enter your limit rate here',
        position: 'right',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
];
